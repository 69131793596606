import { message } from 'antd';
import { useEffect } from 'react'

export function useError({ data, error }: {data: any; error: any;}) {
    useEffect(() => {
        if(data?.message) {
            message.error(data?.message)
            return
        }
        if(error) {
            message.error("An error occured. Please try again")
        }
    }, [error, data])
}
