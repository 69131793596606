import { useMutation } from "react-query";
import { httpPost } from "../../../../utils/query";
import { productsApi } from "../../../../utils/api";
import { ResponseStatus } from "../../../../utils/types";
import { COMMERCE_API_DOMAIN } from "../../../../utils/constants";
import { HighlightProduct,  } from "../types";

export function useProductHighlight() {
    const { data, isLoading, error, mutate } = useMutation<{ status: ResponseStatus }, unknown, HighlightProduct>((payload: HighlightProduct) =>
        httpPost(COMMERCE_API_DOMAIN + productsApi.markAsHighlight, payload),
    );

    return {
        isLoading,
        error: error as any,
        data,
        highlight: (payload: HighlightProduct, onCompleted: () => void) => {
            mutate(payload, {
                onSuccess(data, variables, context) {
                    if(data.status === ResponseStatus.SUCCESS) {
                        onCompleted()
                    }
                },
            })
        }
    }
}
