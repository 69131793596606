import React from 'react'
import { FlexPad, Title } from '../styled'
import { ListItem } from '../../../components/listitem'
import { ListItemIcon, ListItemText } from '@mui/material'
import { IconInvoices, IconScanner } from '../../../components/icons'
import { Switch } from 'antd'
import { Capability } from '../types'

interface CapabilitiesProps {
    editable?: boolean;
    capabilities?: Capability[];
    disabled?: boolean;
    setCapabilities?: (capabilities: Capability[]) => void;
}

export function Capabilities({ editable = true, disabled, capabilities, setCapabilities }: CapabilitiesProps) {
    const onChange = (value: Capability, shouldRemove: boolean) => {
        const values = new Set(capabilities);
        if (values.has(value) && shouldRemove) values.delete(value);
        else values.add(value);
        setCapabilities?.(Array.from(values))
    }
    return (
        <>
            <FlexPad style={{ flexDirection: "column", padding: 0, gap: 0, opacity: disabled ? 0.3 : 1, pointerEvents: disabled ? "none" : "unset" }}>
                <Title>Capabilities</Title>
                <ListItem>
                    <ListItemIcon>
                        <IconInvoices />
                    </ListItemIcon>
                    <ListItemText primary="ChuttApp Pay" />
                    <Switch checked={capabilities?.includes(Capability.PAYMENT)} onChange={(state) => onChange(Capability.PAYMENT, state === false)} disabled={!editable} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <IconScanner />
                    </ListItemIcon>
                    <ListItemText primary="Scanner" />
                    <Switch checked={capabilities?.includes(Capability.SCANNER)} onChange={(state) => onChange(Capability.SCANNER, state === false)} disabled={!editable} />
                </ListItem>
            </FlexPad>
        </>
    )
}
