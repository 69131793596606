/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import { Content } from '../../../components/content'
import { Button } from '../../../components/button'
import { Flex, FlexPad, Pad, Title } from '../styled'
import { Input } from '../../../components/input'
import { Subtitle } from '../../auth/styled'
import { message } from 'antd'
import { InputAdornment, ListItemText, } from '@mui/material'
import { useComerceAppContext, useProduct, useUpdateProduct } from './hooks'
import { CommerceTemplateType, DeliveryDuration, ProductAvailability, } from './types'
import { ColorForm } from './components/ColorForm'
import { Shimmer } from '../../../components/Shimmer'
import { CompactList, ProductImagePreview } from './styled'
import useNav from '../../../utils/hooks/useNav'
import { DataResponseView } from '../../../components/DataResponseView'
import { ListItem } from '../../../components/listitem'
import { Checkbox } from '../../../components/checkbox'
import { useProductHighlight } from './hooks/useProductHighlight'

export function ProductDetailsPage() {
    const { commerceUser } = useComerceAppContext();
    const { params } = useNav();
    const { data, error, isLoading, isRefetching, refetch } = useProduct(commerceUser?.app_id, params?.productId!);

    const [name, setName] = useState(data?.name || '');
    const [caption, setCaption] = useState(data?.caption || '');
    const [description, setDescription] = useState(data?.description || '');
    const [price, setPrice] = useState(data?.price || '');
    const [deliveryDuration, setDeliveryDuration] = useState<DeliveryDuration>(data?.delivery_duration || "3 hours");
    const [colors, setColors] = useState(data?.colors || ['White']);
    const [sizes, setSizes] = useState<string[]>(data?.sizes || ["M", "L"]);
    const [availability, setAvailability] = useState<ProductAvailability>(data?.availability);
    const {data: updateData, error: updateError, isLoading: updateLoading, updateProduct} = useUpdateProduct();
    const {highlight, isLoading: highlightLoading} = useProductHighlight();
    const [highlightText] = useState('')

    const areBasicsNOTValid = !name || !caption || !description || !price || !deliveryDuration;

    const options = {
        [CommerceTemplateType.SHOES]: {
            isValuesNOTValid: colors.length === 0 || sizes.length === 0,
            component: (
                <>
                    <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Sizes</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 5 }}>
                            {["S", "M", "L", "XL", "XXL", "XXXL"].map((a, i) => (
                                <Pad key={i} onClick={() => {
                                    if (sizes.includes(a as any)) {
                                        const newSizes = [...sizes].filter(b => a !== b);
                                        setSizes(newSizes);
                                        return
                                    }
                                    setSizes([...sizes, a as any])
                                }} isActive={sizes.includes(a as any)}>{a}</Pad>
                            ))}
                        </Flex>
                    </FlexPad>
                    <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Colors</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 0 }}>
                            <ColorForm colors={colors} setColors={setColors} />
                        </Flex>
                    </FlexPad>
                </>
            ),
        },
        [CommerceTemplateType.CLOTHING]: {
            component: <>
            <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Sizes</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 5 }}>
                            {["S", "M", "L", "XL", "XXL", "XXXL"].map((a, i) => (
                                <Pad key={i} onClick={() => {
                                    if (sizes.includes(a as any)) {
                                        const newSizes = [...sizes].filter(b => a !== b);
                                        setSizes(newSizes);
                                        return
                                    }
                                    setSizes([...sizes, a as any])
                                }} isActive={sizes.includes(a as any)}>{a}</Pad>
                            ))}
                        </Flex>
                    </FlexPad>
                    <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Colors</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 0 }}>
                            <ColorForm colors={colors} setColors={setColors} />
                        </Flex>
                    </FlexPad>
                    </>,
        },
        [CommerceTemplateType.FOOD]: {
            component: (<>
                <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                    <Title style={{ padding: 0 }}>Calories</Title>
                    <Input placeholder='Eg: 34' />
                </FlexPad>
            </>)
        },
        [CommerceTemplateType.HAIR]: {
            component: <>
             <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Colors</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 0 }}>
                            <ColorForm colors={colors} setColors={setColors} />
                        </Flex>
                    </FlexPad>
            </>,
        },
        [CommerceTemplateType.PHONES]: {
            component: <>
             <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                        <Title style={{ padding: 0 }}>Colors</Title>
                        <Flex style={{ flexWrap: "wrap", gap: 0 }}>
                            <ColorForm colors={colors} setColors={setColors} />
                        </Flex>
                    </FlexPad>
            </>,
        },
        [CommerceTemplateType.WATCH]: {
            component: <></>,
        },
        [CommerceTemplateType.PERFUME]: {
            component: <></>,
        },
    }

    // const OPTION_COMPONENT = options[commerceUser?.type].component;
    const OPTION_VALUES_NOT_VALID = (options[commerceUser?.type] as any).isValuesNOTValid;

    useEffect(() => {
        if (data?.product_id) {
            setName(data?.name || '')
            setCaption(data?.caption || '')
            setDescription(data?.description || '')
            setPrice(data?.price || '')
            setDeliveryDuration(data?.delivery_duration || "3 hours")
            setColors(data?.colors || ['white'])
            setSizes(data?.sizes || ['M', 'L'])
            setAvailability(data?.availability)
        }
    }, [data]);

    const highlightProduct = () => {
        highlight({
            app_id: data?.app_id!,
            product_id: data?.product_id!,
            highlight_text: highlightText
        }, () => {
            refetch();
            message.success("Highlight updated successfully")
        })
    }

    const onSubmit = () => {
        const payload: any = {
            ...data,
            app_id: commerceUser?.app_id,
            name,
            caption,
            delivery_duration: deliveryDuration,
            description,
            price: parseFloat(price as any),
            creator_id: commerceUser?.user_id,
            colors,
            sizes,
            availability
        }
        updateProduct(payload, () => {
            refetch();
            message.success("Product updated successfully")
        })
    }

    useEffect(() => {
        if (error || (data as any)?.message) message.error((data as any)?.message || "Something happened while trying to create product.")
    }, [error, data])

    useEffect(() => {
        if (updateError || (updateData as any)?.message) message.error((updateData as any)?.message || "Something happened while trying to update product.")
    }, [updateError, updateData])

    return (
        <Shimmer.SmoothTransition>
            <Content title='Product details' nested showBackButton>
                <Content.Toolbar>
                    <Button isLoading={updateLoading} disabled={areBasicsNOTValid || OPTION_VALUES_NOT_VALID} onClick={onSubmit} variant='primary'>Update</Button>
                </Content.Toolbar>
                <Content.Main>
                    <DataResponseView
                        content={(
                            <>
                                <Flex style={{ width: '100%' }}>
                                    <Flex style={{ width: '100%', flexDirection: 'column' }}>
                                        <FlexPad style={{ flexDirection: "column", overflow: "hidden", maxHeight: 200, gap: 24 }}>
                                            <Flex style={{ justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <Title style={{ padding: 0 }}>Product Images</Title>
                                                    <Subtitle>You can't change product images.</Subtitle>
                                                </div>
                                            </Flex>
                                            <Flex>
                                                {
                                                    data?.images?.map?.(a => (
                                                        <ProductImagePreview key={a}>
                                                            <img alt={a} src={a} />
                                                        </ProductImagePreview>
                                                    ))
                                                }
                                            </Flex>
                                        </FlexPad>
                                        <FlexPad style={{ gap: 24 }}>
                                            <Input value={name} onChange={e => setName(e.target.value)}
                                                inputProps={{ maxLength: 30, style: { fontSize: 20, fontFamily: "bold" } }}
                                                fullWidth size='medium'
                                                label="Name"
                                                variant='standard'
                                                helperText="Eg: Female Nike shoe"
                                            />
                                        </FlexPad>
                                        <FlexPad>
                                            <Input
                                                value={caption}
                                                onChange={(e) => setCaption(e.target.value)}
                                                inputProps={{ maxLength: 30 }}
                                                fullWidth size='medium'
                                                label="Caption"
                                                variant='standard'
                                                helperText={`Short and at max ${caption.length > 0 ? `${caption.length}/30` : '30'} characters description of your product`}
                                            />
                                        </FlexPad>
                                        <FlexPad style={{ flex: 1 }}>
                                            <Input
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                size='medium' fullWidth maxRows={30} label="Description" multiline variant='standard' helperText="Provide descriptive details of your product." />
                                        </FlexPad>
                                    </Flex>
                                    <Flex style={{ flexDirection: "column", width: 350 }}>
                                        <FlexPad style={{ gap: 24 }}>
                                            <Input value={price} onChange={e => setPrice(e.target.value)}
                                                inputProps={{ maxLength: 30, style: { fontSize: 'var(--fontLarge)', fontFamily: 'bold' } }}
                                                fullWidth size='medium'
                                                label="Price"
                                                variant='standard'
                                                focused
                                                placeholder='0.00'
                                                helperText="The currency will always default to your profile country's currency"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <Title style={{ color: "var(--color) !important", padding: 0 }}>GHS</Title>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FlexPad>
                                        <FlexPad style={{ flexDirection: "column" }}>
                                            <Title style={{ padding: 0 }}>Availability</Title>
                                            <CompactList>
                                                <ListItem>
                                                    <ListItemText primary="In stock" />
                                                    <Checkbox onChange={(state) => setAvailability(ProductAvailability.AVAILABLE)} checked={availability == ProductAvailability.AVAILABLE} />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Out of stock" />
                                                    <Checkbox onChange={(state) => setAvailability(ProductAvailability.OUT_OF_STOCK)} checked={availability == ProductAvailability.OUT_OF_STOCK} />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Sold out" />
                                                    <Checkbox onChange={(state) => setAvailability(ProductAvailability.SOLD_OUT)} checked={availability == ProductAvailability.SOLD_OUT} />
                                                </ListItem>
                                            </CompactList>
                                        </FlexPad>
                                        {/* {OPTION_COMPONENT} */}
                                        <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                                            <Title style={{ padding: 0 }}>Delivery</Title>
                                            <Flex style={{ flexWrap: "wrap" }}>
                                                {["1 hour", "3 hours", "5 hours", "1 day", "2 days", "5 days", "1 week", "2 weeks", "3 weeks", "1 month", "More than 3 months"].map(a => (
                                                    <Pad key={a} onClick={() => setDeliveryDuration(a as any)} isActive={deliveryDuration === a}>{a}</Pad>
                                                ))}
                                            </Flex>
                                        </FlexPad>                                            
                                            <FlexPad style={{ gap: 24, flexDirection: "column" }}>
                                            {
                                                data?.highlight ?
                                                <Subtitle>This product is the current highlight</Subtitle>
                                                :
                                                <Subtitle>Display this product on the home page's banner to for easy clicks.</Subtitle>
                                            }
                                            {data?.highlight ? <Button onClick={highlightProduct} isLoading={highlightLoading} variant='tertiary'>Remove highlight</Button> 
                                            : <Button onClick={highlightProduct} isLoading={highlightLoading} variant='primary'>Highlight this product</Button> }
                                        </FlexPad>
                                    </Flex>
                                </Flex>
                            </>
                        )}
                        data={data}
                        error={error}
                        isLoading={isLoading}
                        isRefetching={isRefetching}
                        onRetry={refetch}
                    />
                </Content.Main>
            </Content>
        </Shimmer.SmoothTransition>
    )
}
