import React, { useMemo } from 'react'
import { StatusCellWrapper } from '../../../wallet/transactions/components/styled';
import { OrderStatus } from '../types';
import { IconCheck, IconClose } from '../../../../components/icons';

export function OrderStatusCell({ status }: { status: OrderStatus }) {
    const { color, icon, background, label } = useMemo(() => {
        switch (status) {
            case OrderStatus.PROCESSING:
                return {
                    color: "var(--yellow600)",
                    background: "var(--yellow10)",
                    label: "Processing",
                }

            case OrderStatus.RECEIVED:
                return {
                    color: "var(--gray600)",
                    background: "var(--gray10)",
                    label: "Received",
                }

            case OrderStatus.SHIPPING:
                return {
                    color: "var(--yellow600)",
                    background: "var(--yellow10)",
                    label: "Shipped",
                }
                case OrderStatus.CANCELLED:
                    return {
                        color: "var(--red600)",
                        background: "var(--red10)",
                        label: "Cancelled",
                        icon: <IconClose size={13} color="var(--green600)" />
                    }
                    case OrderStatus.SHIPMENT_COMPLETE:
                        return {
                            color: "var(--green600)",
                            background: "var(--green10)",
                            label: "Delivered",
                        }
            case OrderStatus.COMPLETED:
                return {
                    color: "var(--green)",
                    background: "var(--green10)",
                    label: "Compeleted",
                    icon: <IconCheck size={13} color="var(--green)" />
                }
            default:
                return {}
        }
    }, [status]);

    return (
        <StatusCellWrapper style={{
            color,
            background
        }}>
            {icon}
            {label}
        </StatusCellWrapper>
    )
}
