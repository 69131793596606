import { create } from "zustand";
import { getStoreItem, saveStoreItem } from "../../../utils";
import { authToken } from "../../../utils/constants";
import { useMutation } from "react-query";
import { _post } from "../../../utils/query";
import { authApi } from "../../../utils/api";
import { Login, LoginResponse, } from "../types";
import { useAccountState } from "./useAccount";

interface useAuthStateOptions {
  token: string | null;
  setToken: (token: string) => void;
  clear: () => void;
}

export const useAuthState = create<useAuthStateOptions>((set, get) => ({
  token: getStoreItem(authToken) || null,
  setToken: (token) => {
    set({ token });
    saveStoreItem(authToken, token)
  },
  clear: () => {
    set({ token: null });
    localStorage.removeItem(authToken)
  }
}))


export function useAuth() {
  const { clear, setToken, token } = useAuthState();
  const { setUser, clear: clearUser } = useAccountState();
  const { isLoading, error, data, mutate } = useMutation<LoginResponse, unknown, Login>((payload: Login) =>
    _post(authApi.login, payload),
  );

  const logout = () => {
    clear();
    clearUser();
    window.location.reload()
  };

  const login = (payload: Login) => {
    mutate(payload, {
      onSuccess(data) {
        const userData = data?.user;
        const tokenData = data?.token;
        if (!tokenData) return;
        setToken(tokenData);
        if (userData) setUser(userData);
        window.location.reload()
      },
    })
  };

  return {
    isLoggedIn: !!token,
    isLoading,
    error: error as any,
    data,
    login,
    logout,
  }
}
