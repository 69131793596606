import React from 'react'
import { CustomerCellContent, CustomerCellSubtitle, CustomerCellTtitle, CustomerCellWrapper } from '../../wallet/transactions/components/styled'
import ReactCountryFlag from 'react-country-flag'
import { Avatar, Badge } from '@mui/material'
import { toUsername } from '../../../utils'
import { User } from '../../auth/types'

export function CustomerCell({ user }: { user: User }) {
    const { username, name, avatar_url, country } = user || {};
    if (!username && !name) return <div style={{color: "var(--secondaryColor)"}}>Unknown</div>;

    return (
        <CustomerCellWrapper>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <ReactCountryFlag className='emojiFlag' style={{ fontSize: 22 }} countryCode={country!} />
                }
            >
                <Avatar sx={{ width: 32, height: 32 }} src={avatar_url} />
            </Badge>
            <CustomerCellContent>
                <CustomerCellTtitle>{name}</CustomerCellTtitle>
                <CustomerCellSubtitle>{toUsername(username)}</CustomerCellSubtitle>
            </CustomerCellContent>
        </CustomerCellWrapper>
    )
}
