import { ordersApi } from "../../../../utils/api";
import { useTableQuery } from "../../../../utils/hooks/useTableQuery";
import { useAccount } from "../../../auth/hooks";
import { Order } from "../types";

export function useOrders(appId: string) {
  const {user_id} = useAccount()
  const options = useTableQuery<Order>({
    query: ordersApi.getAll,
    parameters: { seller_id:  user_id}, // TODO: app_id: appId, 
    queryId: "get-orders" + appId,
    enabled: !!appId,
  })
  return options;
}
