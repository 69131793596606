import React, { useEffect, useState } from 'react'
import { LoginGraphicAnimation } from '../../components/animation/Animations'
import { Logo } from '../../components/brand'
import { AuthWrapper, Flex, Subtitle } from './styled'
import { Input } from '../../components/input'
import { InputAdornment } from '@mui/material'
import { IconPassword, IconUser2 } from '../../components/icons'
import { Button } from '../../components/button'
import { ReactComponent as IOS } from '../../assets/svgs/ios.svg';
import Android from '../../assets/images/android.png';
import { useAuth } from './hooks'
import { message } from 'antd'
import { useError } from '../../utils/hooks/useError'

function SignInPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {login, isLoading, error, data} = useAuth();
    useError({error, data});
    
    const onLogin = () => {
        if(!username) return message.error('Username is required');
        if(!password) return message.error('Password is required');
        login({
            username,
            password
        })
    }

    useEffect(() => {
        if(error) message.error(error?.description || "An error occured while signing in.")
    }, [error])

    return (
        <AuthWrapper>
            <Flex style={{ height: 450, width: 450 }}>
                <LoginGraphicAnimation />
            </Flex>
            <Flex style={{ flexDirection: 'column', gap: 20 }}>
                <Logo showLogoText={true} size={20} />
                <div>
                    <div style={{ textAlign: 'center', textTransform: 'uppercase', fontFamily: 'bold', marginBottom: 8 }}>
                        Dashboard
                    </div>
                    <div style={{ color: 'var(--secondaryColor)', fontSize: 'var(--fontSmall)' }}>You need to have a ChuttApp account to sign in</div>
                </div>
                <Flex style={{ flexDirection: 'column', gap: 12, width: 235 }}>
                    <div>
                        <Input
                            placeholder='username'
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <IconUser2 />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <div>
                    <Input
                        placeholder='password'
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <IconPassword />
                                </InputAdornment>
                            )
                        }}
                    />
                    </div>
                    <Flex style={{width: '100%'}}>
                        <Button onClick={onLogin} isLoading={isLoading} disabled={!username || !password} style={{width: '100%'}} variant='primary'>Sign in</Button>
                    </Flex>
                    <Flex style={{textAlign: 'center', marginTop: 20}}>
                        <Subtitle style={{color: 'var(--color)'}}>Don't have an account?</Subtitle>
                    </Flex>
                    <Flex style={{gap: 8}}>
                        <img src={Android} alt='download android' />
                        <IOS />
                    </Flex>
                </Flex>
            </Flex>
        </AuthWrapper>
    )
}

export default SignInPage