import React, { useEffect, useState } from 'react'
import { Content } from '../../components/content'
import { Button } from '../../components/button'
import { ImageUpload } from '../../components/upload/ImageUpload'
import { Input } from '../../components/input'
import { Flex, FlexPad, Title } from './styled'
import { Capabilities } from './components/Capabilities'
import { SupportedCountries } from './components/SupportedCountries'
import { message, Popconfirm, Switch } from 'antd'
import useNav from '../../utils/hooks/useNav'
import { AppNameInput } from './components/AppNameInput'
import { UrlInput } from './components/UrlInput'
import { Shimmer } from '../../components/Shimmer'
import { useCreateApp } from './hooks/useCreateApp'
import { ResponseStatus } from '../../utils/types'
import { routes } from '../../utils/routes'
import { validURL } from '../../utils'
import { Capability, InAppTypeEnum } from './types'
import { generatePath } from 'react-router-dom'
import { DevUrlInput } from './components/DevUrlInput'
import iphoneFrame from '../../assets/images/iphone-frame.png'
import androidFrame from '../../assets/images/samsung-frame.png'
import { Subtitle } from '../auth/styled'

export function CreateAppPage() {
    const { goBack, navigate } = useNav()
    const [appName, setAppName] = useState('');
    const [appUrl, setAppUrl] = useState('')
    const [imageUrl, setImageUrl] = useState<any>();
    const [caption, setCaption] = useState('')
    const [description, setDescription] = useState('');
    const [devUrl, setDevUrl] = useState('')
    const [capabilities, setCapabilities] = useState<Capability[]>([Capability.PAYMENT]);
    const [supportedCountries, setSupportedCountries] = useState<string[]>([])
    const [isNative, setIsNative] = useState<boolean>(false);

    const { onCreateApp, data, isLoading, error } = useCreateApp();

    const urlVal = isNative ? false : !appUrl;
    const isurlEdited = isNative ? true : !!appUrl;

    const disabled = !appName || urlVal || !imageUrl || !caption || !description;
    const edited = !!appName || isurlEdited || !! !!imageUrl || !!caption || !!description;
    const errorsInEditing = isNative ? false : !validURL(appUrl);

    const createApp = () => {
        onCreateApp({
            caption,
            description,
            icon_url: imageUrl,
            name: appName,
            supported_countries: supportedCountries,
            url: appUrl,
            dev_url: devUrl,
            capabilities: capabilities,
            type: isNative ? InAppTypeEnum.NATIVE : InAppTypeEnum.EXTENSION,
        })
    }

    useEffect(() => {
        if (data?.status === ResponseStatus.SUCCESS && data?.app_id) {
            message.success('App created successfully')
            navigate(generatePath(routes.apps.details.root, { id: data.app_id }))
        }
    }, [data, navigate])

    useEffect(() => {
        if (error) message.error(error?.description || 'An error occured')
    }, [error])

    return (
        <Shimmer.SmoothTransition>
            <Content
                title='Create app'
                showBackButton
                showPopupConfirm={edited}
            >
                <Content.Toolbar>
                    <Popconfirm
                        disabled={!edited}
                        title="You have unsaved changes"
                        description="Are you sure you want to close this page?"
                        okText="Yes"
                        cancelText="No"
                        cancelButtonProps={{
                            style: {
                                background: "transparent",
                                color: 'var(--color)',
                                borderColor: 'var(--borderColor500)'
                            }
                        }}
                        placement='bottom'
                        onConfirm={goBack}
                    >
                        <Button onClick={() => edited ? undefined : goBack()}>Cancel</Button>
                    </Popconfirm>
                    <Button onClick={createApp} isLoading={isLoading} disabled={disabled || errorsInEditing} variant='primary'>Create</Button>
                </Content.Toolbar>
                <Content.Main>
                    <Flex style={{ width: '100%' }}>
                        <Flex style={{ width: '100%', flexDirection: 'column' }}>
                            <FlexPad style={{ flex: 1, gap: 24 }}>
                                <ImageUpload imageUrl={imageUrl} appId={Date.now().toString()} setImageUrl={setImageUrl} />
                                <AppNameInput appName={appName} setAppName={setAppName} />
                            </FlexPad>
                            <Flex>
                                <div style={isNative ? { opacity: 0.3, pointerEvents: "none" } : undefined}>
                                    <FlexPad style={{ flex: 1, marginBottom: 12 }}>
                                        <UrlInput disabled={isNative} appUrl={appUrl} setAppUrl={setAppUrl} />
                                    </FlexPad>
                                    <FlexPad style={{ flex: 1 }}>
                                        <DevUrlInput disabled={isNative} appUrl={devUrl} setAppUrl={setDevUrl} />
                                    </FlexPad>
                                </div>
                                <FlexPad style={{ flex: 1, justifyContent: "center" }}>
                                    <div>
                                        <Flex style={{ alignItems: "center", justifyContent: "space-between", marginBottom: 7 }}>
                                            <div>
                                                <Title style={{ padding: "5px 0px" }}>Use template</Title>
                                                <Subtitle>Set up later.</Subtitle>
                                            </div>
                                            <Switch checked={isNative} onChange={() => setIsNative(!isNative)} />
                                        </Flex>
                                        <div style={{ position: "relative", borderRadius: 12, overflow: "hidden" }}>
                                            <Flex style={{ padding: 10 }}>
                                                <img src={iphoneFrame} alt='iphone-temp' />
                                                <img src={androidFrame} alt='iphone-temp' />
                                            </Flex>
                                            <div style={{ backdropFilter: "blur(2px)", position: "absolute", top: 0, bottom: 0, right: 0, left: 0, borderRadius: 12 }}></div>
                                        </div>
                                    </div>
                                </FlexPad>
                            </Flex>
                            <FlexPad style={{ flex: 1 }}>
                                <Input
                                    value={caption}
                                    onChange={(e) => setCaption(e.target.value)}
                                    inputProps={{ maxLength: 30 }}
                                    fullWidth size='medium' label="Caption" variant='standard' helperText={`Short and at max ${caption.length > 0 ? `${caption.length}/30` : '30'} characters description of your app`} />
                            </FlexPad>
                            <FlexPad style={{ flex: 1 }}>
                                <Input
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    size='medium' fullWidth maxRows={30} label="Description" multiline variant='standard' helperText="Provide descriptive details of your app." />
                            </FlexPad>
                        </Flex>
                        <Flex style={{ flexDirection: "column", width: 350 }}>
                            <Capabilities disabled={isNative} capabilities={capabilities} setCapabilities={setCapabilities} />
                            <SupportedCountries supportedCountries={supportedCountries} setSupportedCountries={setSupportedCountries} />
                        </Flex>
                    </Flex>
                </Content.Main>
            </Content>
        </Shimmer.SmoothTransition>
    )
}
