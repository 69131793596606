export const routes = {
    auth: {
        login: '/login'
    },
    account: {
        root: '/account',
        settings: '/account/settings'
    },
    home: '/',
    apps: {
        root: '/apps',
        details: {
            root: '/apps/details/:id',
            template: {
                root: '/apps/details/:id/template',
                products: '/apps/details/:id/template/products',
                categories: '/apps/details/:id/template/categories',
                orders: '/apps/details/:id/template/orders',
                orderDetails: '/apps/details/:id/template/orders/:orderId/details',
                createProduct: '/apps/details/:id/template/products/add',
                createCategory: '/apps/details/:id/template/categories/add',
                productDetails:'/apps/details/:id/template/products/:productId/details',
            },
            metrics: '/apps/details/:id/metrics',
            deployment: '/apps/details/:id/deployment',
            settings: '/apps/details/:id/settings',
        },
        create: '/apps/create',
    },
    transactions: {
        root: '/transactions',
        details: '/transactions/details/:id',
    },
    invoices: {
        root: '/invoices',
        details: '/invoices/details/:id',
    },
    payouts: {
        root: '/payouts',
        details: '/payouts/details/:id',
    },
    customers: {
        root: '/customers',
        details: '/customers/details/:id',
    },
}