export const authApi = {
    login: '/users/login'
}

export const appsApi = {
    myApps: '/in-apps/author',
    create: '/in-apps/create',
    details: '/in-apps',
    update: '/in-apps/update',
    updateStatus: '/in-apps/update/status',
}

export const transactionsApi = {
    getAll: '/transfer/logs/by',
    getTransaction: '/transfer/transactions'
}

export const statisticsApi = {
    logAppOpens: '/statistics/app/log/opens',
    getStatisticsAcrossAllApps: '/statistics/app/all',
    getAppCustomers: '/statistics/app/customers',
    getAppStatistics: '/statistics/app',
}

export const invoicesApi = {
    getAllCreated: '/invoices'
}

export const appReviewsApi = {
    getAll: '/in-app-review',
}

export const payoutsApi = {
    getAll: '/bank/transactions/by',
}

export const commerceApi = {
    create: '/users/create',
    get: "/users/by/app"
}

export const productsApi = {
    getProductsByApp: "/products/by",
    createProduct: "/products/create",
    getProduct: "/products/by",
    updateProduct: "/products/update",
    markAsHighlight: "/products/highlight",
    getHighlight: "/products/highlight",
}

export const ordersApi = {
    getAll: "/orders",
}