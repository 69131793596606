import React from 'react'
import { Content } from '../../../components/content'
import { DataResponseView } from '../../../components/DataResponseView'
import { useComerceAppContext, useOrders } from './hooks'
import { Logger } from '../../../components/logger';
import { Button } from '../../../components/button';
import { Table, TableProps } from '../../../components/table';
import { DateCell } from './components/DateCell';
import { Order } from './types';
import { TableAmount } from './components/TableAmount';
import { useAccount } from '../../auth/hooks';
import { CustomerCell } from '../../customers/components/CustomerCell';
import { TitleCell } from '../../wallet/transactions/components/IDCell';
import { TableShimmer } from '../../../components/TableShimmer';
import { OrderStatusCell } from './components/OrderStatusCell';

export function OrdersPage() {
    const {currency} = useAccount()
    const { commerceUser, app, onChangeTab } = useComerceAppContext();
    const { data, error, totalCount, isLoading, isRefetching, tableParams, refetch } = useOrders(commerceUser?.app_id);

    // const onClick = () => navigate(generatePath(routes.apps.details.template.orderDetails, {id: params?.id, orderId: "hg"}))

    const columns: TableProps<Order>['columns'] = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'payer',
            render: (_, order) => <TitleCell>{order.order_id.slice(0, 8)}</TitleCell>
        },
        {
            title: 'Customer',
            dataIndex: 'payer',
            key: 'payer',
            render: (_, order) => <CustomerCell user={order.buyer} />
        },
        {
            title: 'Total amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, order) => <TableAmount price={order.total_amount} currency={currency!} />
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, order) => <OrderStatusCell status={order?.status} />
        },
        {
            title: 'Date created',
            dataIndex: 'date',
            key: 'date',
            render: (_, order) => <DateCell date={order?.created_at} />
        },
        // {
        //     title: '',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     fixed: "right",
        //     align: "right",
        //     render: () => (
        //         <MenuDropdown
        //             items={[
        //                 {
        //                     key: 'shipped',
        //                     label: "Mark as shipped",
        //                 },
        //                 {
        //                     key: 'completed',
        //                     label: "Mark as completed",
        //                 },
        //             ]}
        //         />
        //     )
        // },
    ];

    if (!commerceUser?.app_id) return (
        <Logger
            title='Orders section disabled'
            subtitle='You need to to configure your template first'
        >
            <Logger.Actions>
                <Button onClick={() => onChangeTab("root")} variant='primary'>Go to configuration</Button>
            </Logger.Actions>
        </Logger>
    )

    return (
        <Content title='Orders' nested>
            <Content.Main>
                <DataResponseView
                    customLoadingComponent={<TableShimmer configure={['normal', 'user', 'amount', 'normal', 'date']} />}
                     content={(
                        <Table
                            rowKey={({ transfer_id }) => transfer_id}
                            columns={columns}
                            dataSource={data}
                            loading={isRefetching}
                            totalData={totalCount}
                            tableParams={tableParams}
                            leaderLabel={app?.name ? `Total orders for ${app.name}` : 'Orders'}
                            onRefresh={refetch}
                        />
                    )}
                    data={data}
                    error={error}
                    isLoading={isLoading}
                />
            </Content.Main>
        </Content>
    )
}
