import { User } from "../../auth/types";
import { InApp } from "../types";

export enum ProductAvailability {
    OUT_OF_STOCK = 3,
    SOLD_OUT = 2,
    AVAILABLE = 1
}

export enum OrderStatus {
    RECEIVED = "received",
    PROCESSING = "processing",
    SHIPPING = "shipping",
    SHIPMENT_COMPLETE = "shipment-complete",
    COMPLETED = "completed",
    CANCELLED = "cancelled"
}

export enum CommerceTemplateType {
    SHOES = "shoes",
    FOOD = "food",
    CLOTHING = "clothing",
    HAIR = "hair",
    PHONES = "phones",
    // CARS = "cars",
    WATCH = "watch",
    PERFUME = "perfume",
}
export enum CategoryStatus {
    ARCHIVED = 2,
    ACTIVE = 1
}

export type ApparelSize = "S"| "M"| "L"| "XL"| "XXL"| "XXXL";
export type DeliveryDuration =  "1 hour" | "3 hours" | "5 hours" | "1 day" | "2 days" | "5 days" | "1 week" | "2 weeks" | "3 weeks" | "1 month" | "More than 3 months";

export interface Category {
    id: number;
    category_id: string;
    app_id: string;
    label: string;
    image_url: string;
    status: CategoryStatus
}

export interface CommerceUser {
    id: number;
    user_id: string;
    app_id: string;
    app_logo: string;
    app_name: string;
    country: string;
    currency: string;
    type: CommerceTemplateType
    created_at: string;
    updated_at: string;
}

export type CreateCommerceUser = Pick<CommerceUser, "app_id" | "app_name" | "app_logo" | "user_id" | "type">

export interface Product {
    id: number;
    product_id: string;
    app_id: string;
    creator_id: string;
    name: string;
    caption: string;
    description: string;
    price: number;
    currency?: string;
    discounted_price?: number;
    /** First image from the image array */
    imageUrl: string;
    images: string[];
    delivery_duration: DeliveryDuration;
    availability: ProductAvailability;
    /** ==== Mostly for products like watches, shirts, shoes etc */
    colors?: string[];
    sizes?: string[];
    // ... ==============
    /** === For edible products */
    calories?: number;
    // ... ==============
    highlight?: boolean;
    categories: Category[];
    total_reviews: number;
    highlight_text?: string;
    created_at: string;
    updated_at: string;
}

export interface GetProductParams {
    search?: string;
    sizes?: string[];
    colors?: string[];
}

export type CreateProduct = Pick<Product, "app_id" | "caption" | "delivery_duration" | "description" | "images" | "name" | "price" | "calories" | "colors" | "sizes" | "creator_id">;
export type HighlightProduct = Pick<Product, "app_id" | "product_id" | "highlight_text">;

export type TemplateDetailTab = "categories" | "root" | "products" | "orders";
export interface TemplateContext {
    app: InApp;
    commerceUser: CommerceUser;
    refetch: () => void;
    onChangeTab: (value: TemplateDetailTab) => void;
}

export interface OrderItem {
    id: number;
    order_item_id: string;
    order_id: string;
    product_id: string;
    price: number;
    quantity: number;
    metadata?: string;
    created_at: string;
    updated_at: string;
}
export interface Order {
    id: number;
    app_id: string;
    customer_id: string;
    buyer: User;
    seller: InApp | User;
    order_id: string;
    total_amount: number;
    status: OrderStatus;
    shipping_address_id: string;
    created_at: string;
    updated_at: string;
}

export type CreateOrder = Pick<Order, "shipping_address_id" | "customer_id" | "app_id"> & {
    order_items: OrderItem[]
};

export type CreateOrderItem = Omit<OrderItem, "id" | "order_item_id">;